import { Option } from "api/types/_public";

export enum LOGIN_TYPE {
  DEFAULT = 'default',
  GUEST = 'guest',
  KAKAO = 'kakao',
  NAVER = 'naver',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const FREQUENCY_TYPE: any = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
};

export enum CATEGORY_TYPE {
  TREND_INDEX = 1,
  STOCK = 2,
}

export enum PRODUCT_TYPE {
  TICKET = 1,
  SUBSCRIPTION = 2,
}

export enum SUBSCRIPTION_PERIOD_TYPE {
  MONTHLY = 1,
  YEARLY = 2,
}

export enum EVENT_NAME {
  CONNECTION = 'connection',
  DISCONNECT = 'disconnect',
  CONNECTED = 'connected',
  SEND_MESSAGE = 'sendMessage',

  PAYMENT_MESSAGE = 'paymentMessage',

  EXCEPTION = 'exception',
  ERROR = 'error',
}

export enum PAYMENT_PROCESS_STATUS {
  INITIATED = 1, // Initial
  PAYMENT_SUCCESS = 10, // Payment success
  CONFIRM_USER_CARD_SUCCESS = 20, // Confirm user card success
  PAYMENT_VERIFIED = 21, // Payment verified
  CONFIRM_USER_CARD_ERROR = 25, // Confirm user card error
  REQUEST_BILL_ERROR = 30, // Request bill success
  PAY_CANCELED = 40, // User has canceled pay.
  PAYMENT_ERROR = 50, // Payment error
}

export enum WS_EVENT_USER_PATTERNS {
  PAYMENT_NOTIFY = 'payment:notify',
  TOTAL_TICKET_AMOUNT_NOTIFY = 'total-ticket-amount:notify',
}

export enum TYPE_MESSAGES {
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_CANCEL = 'PAYMENT_CANCEL',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
}

export enum SUBSCRIPTION_TYPE {
  COMPANY = 1,
  PEOPLE = 2,
  STOCK = 3,
}
export const TYPE_SUBSCRIPTION_OPTIONS: Option[] = [
  {
    label: `Type_Subscription_${SUBSCRIPTION_TYPE.COMPANY}`,
    value: String(SUBSCRIPTION_TYPE.COMPANY),
  },
  {
    label: `Type_Subscription_${SUBSCRIPTION_TYPE.PEOPLE}`,
    value: String(SUBSCRIPTION_TYPE.PEOPLE),
  },
  {
    label: `Type_Subscription_${SUBSCRIPTION_TYPE.STOCK}`,
    value: String(SUBSCRIPTION_TYPE.STOCK),
  },
];

export enum PLAN_TYPE {
  STARTER = 1,
  LIGHT = 2,
  STANDARD = 3,
  PREMIUM = 4,
  ENTERPRISE = 5,
};

export const PLAN_TYPE_SUBSCRIPTION_OPTIONS: Option[] = [
  {
    label: `Plan_Type_Subscription_${PLAN_TYPE.STARTER}`,
    value: String(PLAN_TYPE.STARTER),
  },
  {
    label: `Plan_Type_Subscription_${PLAN_TYPE.LIGHT}`,
    value: String(PLAN_TYPE.LIGHT),
  },
  {
    label: `Plan_Type_Subscription_${PLAN_TYPE.STANDARD}`,
    value: String(PLAN_TYPE.STANDARD),
  },
  {
    label: `Plan_Type_Subscription_${PLAN_TYPE.PREMIUM}`,
    value: String(PLAN_TYPE.PREMIUM),
  },
  {
    label: `Plan_Type_Subscription_${PLAN_TYPE.ENTERPRISE}`,
    value: String(PLAN_TYPE.ENTERPRISE),
  },
];

export const COLOR_PLAN_TYPE_SUBSCRIPTION_OPTIONS: string[] = ['secondary', 'primary', 'secondary', 'success', 'warning', 'info'];

export const SORT_COLUMN_TABLE_RANKING = [
  { uuid: 'rank', isShow: true, isDisabled: true, isDragDisabled: false },
  { uuid: 'keyword', isShow: true, isDisabled: true, isDragDisabled: false },
  { uuid: 'user_participation', isShow: true, isDisabled: true, isDragDisabled: false },
  { uuid: 'index_chart', isShow: true, isDisabled: true, isDragDisabled: false },
  { uuid: 'trend_index', isShow: true, isFixed: true, isDisabled: true, isDragDisabled: false },
  { uuid: 'category', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false },
  { uuid: 'subcategory', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false },
  { uuid: 'search_index', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false },
  { uuid: 'p_index', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false },
  { uuid: 'stock_index', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false },
  { uuid: 'viral_index', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false },
  { uuid: 'news_index', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false },
];

export const SORT_COLUMN_TABLE_BY_PEOPLE_RANKING = [
  { uuid: 'rank', isShow: true, isDisabled: true, isDragDisabled: false },
  { uuid: 'keyword', isShow: true, isDisabled: true, isDragDisabled: false },
  { uuid: 'user_participation', isShow: true, isDisabled: true, isDragDisabled: false },
  { uuid: 'index_chart', isShow: true, isDisabled: true, isDragDisabled: false },
  { uuid: 'category', isShow: true, isDisabled: false, isDragDisabled: false },
  { uuid: 'subcategory', isShow: true, isDisabled: false, isDragDisabled: false },
  { uuid: 'trend_index', isShow: true, isDisabled: true, isDragDisabled: false },
  { uuid: 'search_index', isShow: true, isDisabled: false, isDragDisabled: false },
  { uuid: 'p_index', isShow: true, isDisabled: false, isDragDisabled: false },
  { uuid: 'stock_index', isShow: true, isDisabled: false, isDragDisabled: false },
  { uuid: 'viral_index', isShow: true, isDisabled: false, isDragDisabled: false },
  { uuid: 'news_index', isShow: true, isDisabled: false, isDragDisabled: false },
];

export const TYPE_PARTICIPATION_TEMPLATE_IS_VOTE = "1";
export const TYPE_PARTICIPATION_TEMPLATE_IS_SURVEY = "2";

export const TYPE_PARTICIPATION_TEMPLATE_OPTIONS: Option[] = [
  {
    label: "Vote", // Vote
    value: TYPE_PARTICIPATION_TEMPLATE_IS_VOTE,
  },
  {
    label: "Survey", // Survey
    value: TYPE_PARTICIPATION_TEMPLATE_IS_SURVEY,
  }
];

export enum CATEGORY_RANK_TYPE {
  POPULAR = 1,
  ISSUE = 2,
}

export enum CATEGORY_INDEX_RANK_TYPE {
  TREND_INDEX = 3,
  SEARCH_INDEX = 4,
  NEWS_INDEX = 5,
  VIRAL_INDEX = 6,
  P_INDEX = 7,
}

export const SORT_BY_INDEX_RANK_TYPE = {
  [String(CATEGORY_INDEX_RANK_TYPE.TREND_INDEX)]: 'trend_index',
  [String(CATEGORY_INDEX_RANK_TYPE.SEARCH_INDEX)]: 'search_index',
  [String(CATEGORY_INDEX_RANK_TYPE.NEWS_INDEX)]: 'news_index',
  [String(CATEGORY_INDEX_RANK_TYPE.VIRAL_INDEX)]: 'viral_index',
  [String(CATEGORY_INDEX_RANK_TYPE.P_INDEX)]: 'p_index',
}

export const PATH_CATEGORY_RANK_TYPE: any = {
  [`${CATEGORY_RANK_TYPE.POPULAR}`]: 'popular',
  [`${CATEGORY_RANK_TYPE.ISSUE}`]: 'issue',
};

export enum CATEGORY_GROUP_TYPE {
  PEOPLE = 1,
  BUSINESS = 2,
  CORPORATION = 3,
  CONTENTS = 4,
  GAME = 5
}

export const PATH_CATEGORY_GROUP_TYPE: any = {
  [`${CATEGORY_GROUP_TYPE.PEOPLE}`]: 'people',
  [`${CATEGORY_GROUP_TYPE.BUSINESS}`]: 'business',
  [`${CATEGORY_GROUP_TYPE.CORPORATION}`]: 'corporation',
  [`${CATEGORY_GROUP_TYPE.CONTENTS}`]: 'content',
  [`${CATEGORY_GROUP_TYPE.GAME}`]: 'game',
};

export enum CATEGORY_SOURCE_TYPE {
  DEFAULT = 1,
  MELON_CELEB = 2,
  DAUM_BASEBALL_PLAYER = 3,
  DAUM_GOLF_PLAYER = 4,
  DAUM_SOCCER_PLAYER = 5,
}

export const KEY_CATEGORY_SOURCE_TYPE: any = {
  [`${CATEGORY_SOURCE_TYPE.DEFAULT}`]: 'default',
  [`${CATEGORY_SOURCE_TYPE.MELON_CELEB}`]: 'singer',
  [`${CATEGORY_SOURCE_TYPE.DAUM_BASEBALL_PLAYER}`]: 'base_ball_player',
  [`${CATEGORY_SOURCE_TYPE.DAUM_GOLF_PLAYER}`]: 'golf_player',
  [`${CATEGORY_SOURCE_TYPE.DAUM_SOCCER_PLAYER}`]: 'soccer_player',
}

export enum KEYWORD_SOURCE_TYPE {
  ACTOR = 1,
  BASE_BALL_PLAYER = 2,
  GOLF_PLAYER = 3,
  SINGER = 4,
  SOCCER_PLAYER = 5,
  YOUTUBER = 6,
  GAME = 7,
}