import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useProfile } from "../components/Hooks/UserHooks";

import authApi from "api/authApi";
import { loginSuccess } from "store/auth/login/reducer";
import { useDispatch } from "react-redux";
import { myTicket as getOnMyTicket } from "store/voting/thunk";

const AuthProtected = (props: any) => {

  return <>{props.children}</>;
};


export default AuthProtected;