import { createSlice } from "@reduxjs/toolkit";
export interface IState {
  durationResponses: {
    name: string,
    time: number
  }[] | null,
  error: any,
};

export const initialState: IState = {
  durationResponses: null,
  error: {},
};

const GlobalSlice = createSlice({
  name: "Global",
  initialState,
  reducers: {
    changeDurationResponsesAction(state: any, action : any) {
      state.durationResponses = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {changeDurationResponsesAction} = GlobalSlice.actions;

export default GlobalSlice.reducer;