import authApi from "api/authApi";
import { useFormik } from "formik";
import { LOGIN_TYPE } from "helpers/constans";
import { useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loginSuccess } from "store/auth/login/reducer";
import { myTicket as getOnMyTicket } from "store/voting/thunk";
import { useProfile } from "ui/components/Hooks/UserHooks";
import useToastNotifications from "ui/components/Hooks/useToastNotifications";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { IMAGES } from '../../constant/theme';


const Login: React.FC = () => {
	const { t, i18n } = useTranslation();
	const [isShow, setIsShow] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { notify } = useToastNotifications();
	const dispatch = useDispatch<any>();
	const navigate = useNavigate();
	const { userProfile } = useProfile();

	const handleSubmit = async (values: any) => {
		try {
			if (isLoading) {
				return;
			}
			setIsLoading((_prev) => true);
			const data = {
				email: values?.email,
				password: values?.password,
			};
			const response: any = await authApi.login({
				...data,
				provider_type: LOGIN_TYPE.DEFAULT
			});
			setIsLoading((_prev) => false);
			if (response?.code === 200) {
				notify('success', t('Login success.'));
				let finalLogin: any = response?.data;
				localStorage.setItem("authUser", JSON.stringify(finalLogin));
				dispatch(loginSuccess(finalLogin));
				dispatch(getOnMyTicket({}));
				if (!response?.data?.email_verified_at) {
					navigate('/verify-email', { state: { email: response?.data?.email || '' } });
				} else {
					const referralUrl = localStorage.getItem('referralUrl') || '/';
					localStorage.removeItem('referralUrl'); // Clean up after use
					navigate(referralUrl);
				}
			}
		} catch (errors: any) {
			setIsLoading((_prev) => false);
			notify('error', t(`${errors || ''}`));
			return errors;
		}
	};

	const validationSchema = Yup.object({
		email: Yup.string().email(`${t('Please enter a valid email address')}`).max(200, t('At Maximum 200 characters')).required(`${t('Please enter your email address.')}`),
		password: Yup.string()
			.matches(/^\S.*\S$|^\S$/, t("Your password can't start or end with a blank space"))
			.max(200, t('At Maximum 200 characters'))
			.required(t('Please enter a password.')), // .min(8, t('At least 8 characters'))
	});

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema,
		onSubmit: handleSubmit,
	});

	function popupwindow(url: string, title: string, w: number, h: number) {
		// eslint-disable-next-line no-restricted-globals
		var left = (screen.width / 2) - (w / 2);
		// eslint-disable-next-line no-restricted-globals
		var top = (screen.height / 2) - (h / 2);
		return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
	}

	const handleGoogleLogin = (e: any) => {
		e.preventDefault();
		const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
		const redirectUri = `${window.location.origin}/googlecallback`;
		const scope = "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email";
		const googleAuthUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=${scope}`;
		popupwindow(googleAuthUrl, "_blank", 500, 700);
	};

	const handleKakaoLogin = (e: any) => {
		e.preventDefault();
		const clientId = process.env.REACT_APP_KAKAO_REST_API_KEY || "";
		const redirectUri = `${window.location.origin}/kakaocallback`;
		const kakaoAuthUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`;
		popupwindow(kakaoAuthUrl, "_blank", 500, 700);
	};

	const handleNaverLogin = (e: any) => {
		e.preventDefault();
		const clientId = process.env.REACT_APP_NAVER_REST_API_KEY || "";
		const redirectUri = `${window.location.origin}/navercallback`;
		const stateValue = uuidv4();
		const naverAuthUrl = `https://nid.naver.com/oauth2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&state=${stateValue}`;
		popupwindow(naverAuthUrl, "_blank", 500, 700);
	};

	useEffect(() => {
		const handleMessage = (event: any) => {
			if (event.data.type === 'LOGIN_SOCIAL_ERROR') {
				notify('error', t(`${event.data.message}`));
			}
			if (event.data.type === 'LOGIN_SOCIAL_SUCCESS') {
				const referralUrl = localStorage.getItem('referralUrl') || '/';
				localStorage.removeItem('referralUrl'); // Clean up after use
				navigate(referralUrl);
				notify('success', t(`${event.data.message}`));
			}
		};
		window.addEventListener('message', handleMessage);
		return () => {
			window.removeEventListener('message', handleMessage);
			setIsLoading((_prev) => false);
		};
	}, []);

	function trimString(input: string = '') {
		if (input.length > 210) {
			return input.substring(0, 210);
		}
		return input;
	}

	useEffect(() => {
		if (!!userProfile?.id) {
			navigate('/');
		}
	}, [userProfile]);

	return (
		<>
			<div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
				<div className="login-aside text-center  d-flex flex-column flex-row-auto justify-content-center">
					<div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
						<div className="text-center mb-lg-4 mb-2 pt-4 pt-md-0 logo">
							<Link to="/" className="d-block d-md-none" >
								<img src={IMAGES.LogoRankifyDark} alt="logo" style={{ width: 'auto' }} />
							</Link>
							<Link to="/" className="d-none d-md-block">
								<img src={IMAGES.LogoRankifyLight} alt="logo" style={{ width: 'auto' }} />
							</Link>
						</div>
						<h3 className="mb-2 text-white d-none d-md-block">{t('Welcome back!')}</h3>
						<p className="mb-4 d-none d-md-block">{t('Experience various indicators in different fields now')}</p>
					</div>
				</div>
				<div className="container flex-row-fluid d-flex flex-column justify-content-start justify-content-md-center position-relative overflow-hidden p-7 mx-auto">
					<div className="d-flex justify-content-center h-100 align-items-center">
						<div className="authincation-content style-2">
							<div className="row no-gutters">
								<div className="col-xl-12 tab-content">
									<div id="sign-up" className="auth-form tab-pane fade show active  form-validation">
										<form onSubmit={formik.handleSubmit}>
											<div className="text-center mb-4">
												<h3 className="text-center mb-2 text-dark">{t('Sign In')}</h3>
												{/* <span>Your Social Campaigns</span> */}
											</div>
											{/* <div className="sepertor">
												<span className="d-block mb-4 fs-13">{t('with email')}</span>
											</div> */}
											<div className="mb-3">
												<label htmlFor="exampleFormControlInput1" className="form-label required">{t('Email address')}</label>
												<input type="text" className="form-control"
													name="email"
													value={formik?.values?.email}
													onChange={(e) => formik.setFieldValue("email", trimString(e?.target?.value || ""))}
													placeholder={t('Please enter your email address.')}
													autoComplete="off"
												/>
												{(formik.touched.email && formik.errors.email) && (<div className="d-flex justify-content-start align-items-center mt-2">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
														<path d="M12 4L4 12" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
														<path d="M12 12L4 4" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
													</svg>
													<div className="ms-1">
														{formik.errors.email || ''}
													</div>
												</div>)}
											</div>
											<div className=" position-relative">
												<label className="form-label required">{t('Password')}</label>
												<input
													type={isShow ? 'text' : 'password'}
													className="form-control"
													name="password"
													value={formik?.values?.password}
													placeholder={t('Please enter a password.')}
													autoComplete="off"
													onChange={(e) =>
														formik.setFieldValue("password", trimString(e?.target?.value || ""))
													}
													style={{ paddingRight: '45px' }}
												/>
												<span className={`show-pass eye ${isShow ? 'active' : ''} `} onClick={() => setIsShow((prev) => !prev)}>
													<i className="fa fa-eye" />
													<i className="fa fa-eye-slash" />
												</span>
											</div>
											<div className="mb-3">
												{(formik.touched.password && formik.errors.password) && (<div className="d-flex justify-content-start align-items-center mt-2">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
														<path d="M12 4L4 12" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
														<path d="M12 12L4 4" stroke="#FD5353" stroke-width="1.5" stroke-linecap="round" />
													</svg>
													<div className="ms-1">
														{formik.errors.password || ''}
													</div>
												</div>)}
											</div>
											<div className="form-row d-flex justify-content-between mt-4 mb-2">
												<div className="mb-3">
													<div className="form-check custom-checkbox mb-0">
														<input type="checkbox" className="form-check-input" id="customCheckBox1" />
														<label className="form-check-label" htmlFor="customCheckBox1">{t('Remember my preference')}</label>
													</div>
												</div>
											</div>
											<button className="btn btn-sm btn-block btn-primary 4" type="submit" disabled={isLoading}>
												<div className="fs-14" style={{ lineHeight: '28px', fontWeight: '500' }}>
													{isLoading ? <Spinner size="sm"></Spinner> : t('Sign In')}
												</div>
											</button>
										</form>
										<div className="row mb-4">
											<div className="col-12 mt-3" onClick={handleKakaoLogin}>
												<Link to={"#"} className="btn btn-outline-none btn-sm btn-block d-flex justify-content-between align-items-center" style={{ backgroundColor: '#F3DF4A', color: '#1A1A1C' }}>
													<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
														<rect width="28" height="28" rx="14" fill="#F3DF4A" style={{ fontWeight: '500' }} />
														<path d="M14 5C8.475 5 4 8.46535 4 12.745C4 15.5308 5.9 17.9675 8.74375 19.3328C8.53125 20.0982 7.9875 22.1063 7.875 22.5349C7.7375 23.0676 8.075 23.0615 8.29375 22.9145C8.4625 22.8043 11.0312 21.09 12.1438 20.3553C12.7437 20.441 13.3625 20.49 14 20.49C19.525 20.49 24 17.0246 24 12.745C24 8.46535 19.525 5 14 5Z" fill="#381E1F" />
													</svg>
													<div className="flex-grow-1 text-center fs-14 fw-500" >
														{t('Sign in with Kakao')}
													</div>
													<div style={{ width: '28px', height: '28px' }}></div>
												</Link>
											</div>
											<div className="col-12 mt-3">
												<Link to={"#"} className="btn btn-sm btn-block d-flex justify-content-between align-items-center" onClick={handleNaverLogin} style={{ backgroundColor: '#03CF5D', color: '#FFF' }}>
													<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
														<rect width="28" height="28" rx="14" fill="#03CF5D" />
														<path d="M17.1671 5V14.5344L10.8329 5H4V23.8889H10.808V14.3802L17.1421 23.8889H24V5H17.1671Z" fill="white" />
													</svg>
													<div className="flex-grow-1 text-center fs-14 " style={{ fontWeight: '500' }} >{t('Sign in with Naver')}</div>
													<div style={{ width: '28px', height: '28px' }}></div>
												</Link>
											</div>
											<div className="col-12 mt-3">
												<Link to={"#"} className="btn btn-outline-dark btn-sm btn-block d-flex justify-content-between align-items-center" onClick={handleGoogleLogin} style={{ border: '1px solid  #E1E1E1', backgroundColor: '#FFF', color: '#1A1A1C' }}>
													<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M24.56 14.2502C24.56 13.4692 24.4913 12.7212 24.3593 12.0007H14V16.255H19.9208C19.665 17.63 18.8895 18.796 17.7263 19.5742V22.3352H21.282C23.361 20.4212 24.5628 17.5997 24.5628 14.2502H24.56Z" fill="#3E82F1" />
														<path fill-rule="evenodd" clip-rule="evenodd" d="M14.0001 25C16.9701 25 19.4588 24.0155 21.2793 22.3353L17.7236 19.5743C16.7391 20.2343 15.4796 20.6248 13.9973 20.6248C11.1318 20.6248 8.70632 18.6888 7.84282 16.09H4.16882V18.939C5.97832 22.5333 9.69907 25 14.0001 25Z" fill="#32A753" />
														<path fill-rule="evenodd" clip-rule="evenodd" d="M7.8455 16.09C7.6255 15.43 7.50175 14.726 7.50175 14C7.50175 13.274 7.6255 12.57 7.8455 11.91V9.06097H4.16875C3.42625 10.546 3 12.2262 3 14C3 15.7737 3.42625 17.454 4.16875 18.939L7.84275 16.09H7.8455Z" fill="#F9BB00" />
														<path fill-rule="evenodd" clip-rule="evenodd" d="M14.0001 7.37525C15.6143 7.37525 17.0663 7.93075 18.2048 9.01975L21.3591 5.8655C19.4561 4.089 16.9646 3 14.0001 3C9.69908 3 5.98107 5.464 4.16882 9.061L7.84282 11.91C8.70907 9.31125 11.1318 7.37525 13.9973 7.37525H14.0001Z" fill="#E74133" />
													</svg>
													<div className="flex-grow-1 text-center fs-14" style={{ fontWeight: '500' }}>
														{t('Sign in with Google')}
													</div>
													<div style={{ width: '28px', height: '28px' }}></div>
												</Link>
											</div>
										</div>
										<div className="new-account mt-3 text-center">
											<p className="font-w500">{t("Don't have an account")}? <Link className="text-primary" to="/sign-up" >{t('Sign Up Account')}</Link></p>
										</div>
									</div>
									{/* <div className="d-flex align-items-center justify-content-center">
										<Link to={"#"} className="text-primary">{t('Terms')}</Link>
										<Link to={"#"} className="text-primary mx-5">{t('Plans')}</Link>
										<Link to={"#"} className="text-primary">{t('Contact Us')}</Link>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
