import { Route, Routes } from "react-router-dom";
/// Css
import "./../chart.css";
import "./../index.css";
import "./../step.css";

//Scroll To Top
import ScrollToTop from "./../layouts/ScrollToTop";

/// Dashboard

// category

import Error404 from "./../pages/error/Error404";

import OfficialMediaIntroduce from "ui/pages/introduction/OfficialMediaIntroduce";
import ServiceIntroduction from "./../pages/introduction/ServiceIntroduction/index_v2";

const Markup = () => {

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<ServiceIntroduction />}
        />
        <Route
          path="/official-media"
          element={<OfficialMediaIntroduce />}
        />
        <Route path="/*" element={<Error404 />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};


export default Markup;
